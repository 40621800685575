/* -------------------------------------------------------------------------- */
/*                              👋 About Us page                              */
/* -------------------------------------------------------------------------- */

import * as React from 'react'
import Header from 'components/Header'
import TeamHomepageSection from 'components/Home/components/Team'
import ContactHomepageSection from 'components/Home/components/Contact'
import { TEAM_MEMBERS } from './team'

export interface IAboutUsPageProps { }

export default class AboutUsPage extends React.Component<IAboutUsPageProps> {
  public render() {
    return (
      <>
        <Header isCustomHeader type="aboutus" />
        <TeamHomepageSection
          teamMembers={TEAM_MEMBERS}
          hideAllButton
          largeList
        />
        <ContactHomepageSection />
      </>
    )
  }
}
