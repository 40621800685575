/* -------------------------------------------------------------------------- */
/*                         📅 Schedule Options display                        */
/* -------------------------------------------------------------------------- */

import './index.scss'
import * as React from 'react'
import { Package, ScheduleEntry } from 'helpers/types'
import { shiftColor } from 'helpers/util'
import { DEFAULT_COURSE_COLOR } from 'helpers/variables'
import PackageBox from 'components/common/PackageBox'
import ScheduleEntryBox from 'components/common/ScheduleEntryBox'
import PersonDetails, { IPersonDetailsState } from '../PersonDetails'

import netopiaBanner from './netopia.jpg'

export interface IScheduleOptionsProps {
  selectedPackage: Package
  scheduleOptions: ScheduleEntry[]
  onPaymentRequest: (
    data: IPersonDetailsState,
    scheduleOption: ScheduleEntry
  ) => void
}

export interface IScheduleOptionsState {
  selectedScheduleOption: number
}

export default class ScheduleOptions extends React.Component<
  IScheduleOptionsProps,
  IScheduleOptionsState
> {
  state: IScheduleOptionsState = {
    selectedScheduleOption: -1
  }

  public render() {
    const { selectedPackage } = this.props

    return (
      <section className='ScheduleOptions' style={this.__style}>
        <header>
          <h1>înscriere la {this.__registeringClasses}</h1>
          {this.__titleText}
        </header>
        <div className='content'>
          <div className='scheduleList'>
            <PackageBox {...selectedPackage} />
            {this.__scheduleEntries}
          </div>
          <PersonDetails
            showVoucherOption={this.props.selectedPackage.courses.length === 1}
            price={this.props.selectedPackage.price}
            onClick={this.onPaymentRequest}
          />
          <div className='netopiaBanner'>
            <span>Plăți procesate prin</span>
            <img alt='netopia' src={netopiaBanner} />
          </div>
        </div>
      </section>
    )
  }

  private onPaymentRequest = (data: IPersonDetailsState) => {
    const { selectedScheduleOption } = this.state
    const { scheduleOptions } = this.props

    const selectedOption: ScheduleEntry | undefined = scheduleOptions.find(
      option => option.id === selectedScheduleOption
    )

    if (selectedOption === undefined) {
      console.log('no opt')
      return
    }

    this.props.onPaymentRequest(data, selectedOption)
  }

  private __setActiveOption = (id: number) =>
    this.setState({ selectedScheduleOption: id })

  private get __style(): React.CSSProperties {
    const { color } = this.props.selectedPackage.courses[0]

    const primaryColor = color || DEFAULT_COURSE_COLOR
    const secondaryColor = shiftColor(primaryColor)

    return {
      background: `linear-gradient(to top right, ${primaryColor}, ${secondaryColor})`
    }
  }

  private get __registeringClasses() {
    const { selectedPackage } = this.props
    return selectedPackage.courses.reduce(
      (prev, course) => `${prev}${prev === '' ? '' : ', '}${course.title}`,
      ''
    )
  }

  private get __titleText() {
    const {
      selectedPackage: { courses }
    } = this.props

    let extraText = null

    if (courses.length !== 1) {
      extraText = (
        <p>{`Ai ales să te înscrii la ${courses.length} cursuri. Semestrul acesta vei începe cu ${courses[0].title}, iar celelalte cursuri din pachet le vei urma în semestrele următoare.`}</p>
      )
    }

    return (
      <>
        {extraText}
        <p>
          Alege ziua și ora la care vrei să participi pentru clasa{' '}
          {courses[0].title}
        </p>
      </>
    )
  }

  private get __scheduleEntries() {
    const { scheduleOptions } = this.props
    const { selectedScheduleOption } = this.state

    return scheduleOptions.map((entry, key) => (
      <ScheduleEntryBox
        key={key}
        isSelected={selectedScheduleOption === entry.id}
        onClick={this.__setActiveOption}
        {...entry}
      />
    ))
  }
}
