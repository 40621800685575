/**
 * 🏠 Homepage routing component
 *
 * * Pulls in API data and manages high-level state
 * ? TODO: Currently it only renders the main Home component
 */

import * as React from 'react'
import HomePage from '../../pages/Home'

export interface IHomeRouteProps {}

export default class HomeRoute extends React.Component<IHomeRouteProps, any> {
  public render() {
    return <HomePage />;
  }
}
