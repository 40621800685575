/**
 * 🛠 Various utility functions
 */

import { SHADE_SHIFT_AMOUNT } from './variables'

/**
 * Shifts a color by a pre-defined amout (to keep consistency)
 * @param color
 */
export function shiftColor(colorCode: string): string {
  const amount = SHADE_SHIFT_AMOUNT

  var R = parseInt(colorCode.substring(1, 3), 16)
  var G = parseInt(colorCode.substring(3, 5), 16)
  var B = parseInt(colorCode.substring(5, 7), 16)

  R = parseInt(((R * (100 + amount)) / 100).toString(), 10)
  G = parseInt(((G * (100 + amount)) / 100).toString(), 10)
  B = parseInt(((B * (100 + amount)) / 100).toString(), 10)

  R = R < 255 ? R : 255
  G = G < 255 ? G : 255
  B = B < 255 ? B : 255

  var RR = R.toString(16).length === 1 ? '0' + R.toString(16) : R.toString(16)
  var GG = G.toString(16).length === 1 ? '0' + G.toString(16) : G.toString(16)
  var BB = B.toString(16).length === 1 ? '0' + B.toString(16) : B.toString(16)

  return '#' + RR + GG + BB
}
