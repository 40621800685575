/* -------------------------------------------------------------------------- */
/*              📚 Component that displays a category of courses              */
/* -------------------------------------------------------------------------- */

import './index.scss'
import * as React from 'react'

/* ---------------------------- 🤝 Common imports --------------------------- */
import CourseBox from 'components/common/CourseBox'

/* ------------------------------- 🩹 Helpers ------------------------------- */
import { Course } from 'helpers/types'
import { shiftColor } from 'helpers/util'

/* ------------------------------ 🗿 Constants ------------------------------ */
const DEFAULT_BOX_COLOR = '#999999'

export interface ICoursesListingProps {
  title: string
  description: string
  color: string
  courses: Course[]
  onCourseClick: (course: Course) => void
  isLightText?: boolean
  isHalfWidth?: boolean
  isDisabled?: boolean
}

export default class CoursesListing extends React.Component<
  ICoursesListingProps
  > {
  public render() {
    const { title, description } = this.props

    return (
      <section className={this.__className} id={title} style={this.__style}>
        {this.__classEndedWindow}
        <div className='top'>
          <header>
            <h1>{title}</h1>
            <p> {description} </p>
          </header>
        </div>
        <div className='list'>{this.__courseBoxes}</div>
      </section>
    )
  }

  private get __className() {
    const { isHalfWidth, isLightText } = this.props

    let className = 'CoursesListing'

    if (isLightText) {
      className += ' lightText'
    }

    if (isHalfWidth) {
      className += ' halfWidth'
    }

    return className
  }

  private get __style(): React.CSSProperties {
    const { color } = this.props

    const primaryColor = color
    const secondaryColor = shiftColor(primaryColor)

    return {
      background: `linear-gradient(to top right, ${primaryColor}, ${secondaryColor})`
    }
  }

  private get __classEndedWindow() {
    if (!this.props.isDisabled) {
      return null
    }

    return (
      <div className='classEnded'>
        <h1>înscrierile la aceste clase s-au terminat.</h1>
        <h2>
          Te așteptăm semestrul următor cu noile clase de {this.props.title}!
        </h2>
      </div>
    )
  }

  private get __courseBoxes() {
    const { courses, onCourseClick } = this.props

    return courses.map((course, key) => (
      <CourseBox
        key={key}
        title={course.title}
        description={course.description}
        price={course.price}
        backgroundColor={course.color ? course.color : DEFAULT_BOX_COLOR}
        onRegister={() => onCourseClick(course)}
        showPrice={(course.isDisabled ? false : true) as any}
      />
    ))
  }
}
