/**
 * 🏃‍ Projects homepage section
 */

import './index.scss'

/* ---------------------------- 🌍 Global imports --------------------------- */
import * as React from 'react'
import ReactImageGallery, { ReactImageGalleryItem } from 'react-image-gallery'

/* ---------------------------- 🤝 Common imports --------------------------- */
import Button from 'components/common/Button'

/* ---------------------------- 🏠 Local imports ---------------------------- */
import { Project } from './types'

export interface IProjectsProps {
  projects: Project[]
}
export interface IProjectsState {
  selectedProject: Project
}

export default class ProjectsHomepageSection extends React.Component<
  IProjectsProps,
  IProjectsState
> {
  state: IProjectsState = {
    selectedProject: this.props.projects[0]
  }

  get projectIcons() {
    return (
      <div className='projectIcons'>
        {this.props.projects.map((project, key) => (
          <img
            src={
              project.name === this.state.selectedProject.name
                ? project.logo.color
                : project.logo.white
            }
            onClick={() => this.setState({ selectedProject: project })}
            key={key}
            alt=''
          />
        ))}
      </div>
    )
  }

  get gallery() {
    return (
      <div className='gallery'>
        <ReactImageGallery
          showNav
          showBullets
          useBrowserFullscreen
          showPlayButton={false}
          showThumbnails={false}
          items={this.state.selectedProject.gallery.map(
            (image): ReactImageGalleryItem => ({
              original: image
            })
          )}
        />
      </div>
    )
  }

  get details() {
    const { selectedProject } = this.state

    return (
      <div className='details'>
        <div className='text'>
          <img src={selectedProject.logo.color} className='logo' alt='' />
          <h1>{selectedProject.name}</h1>
          <h2>{selectedProject.type}</h2>
          <p>{selectedProject.description}</p>
          {/*<div className='projectDetails'>
            <div className='iconLabel'>
              <img alt='' src='http://picsum.photos/50/50?random' />
              <span>{selectedProject.location}</span>
            </div>
            <div className='iconLabel'>
              <img alt='' src='http://picsum.photos/50/50?random' />
              <span>{selectedProject.date}</span>
            </div>
          </div>
    */}
        </div>
        <div className='signup'>
          <a
            href={selectedProject.url}
            target='_blank'
            rel='noopener noreferrer'
          >
            <Button isLight>Înscrie-te</Button>
          </a>
          <div className='sponsors'>
            {selectedProject.sponsors.length !== 0 && (
              <div className='group'>
                <h2>Sponsori</h2>
                <div className='logos'>
                  {selectedProject.sponsors.map((sponsor, key) => (
                    <img alt='' src={sponsor} key={key} />
                  ))}
                </div>
              </div>
            )}
            {selectedProject.sponsors.length !== 0 && (
              <div className='group'>
                <h2>Parteneri</h2>
                <div className='logos'>
                  {selectedProject.partners.map((partner, key) => (
                    <img alt='' src={partner} key={key} />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }

  public render() {
    return (
      <section className='HomepageProjects'>
        <div className='top'>
          <header>
            <h1>proiecte</h1>
            <p>
              Academia ccna.ro nu este doar despre cursuri - vino să ne distrăm
              căutând comori, jucându-ne on-line sau concentrați pe un board
              game fain.
            </p>
          </header>
          {this.projectIcons}
        </div>
        <div className='projectDetails'>
          {this.gallery}
          {this.details}
        </div>
      </section>
    )
  }
}
