/* -------------------------------------------------------------------------- */
/*                          🤼 Team Homepage Section                          */
/* -------------------------------------------------------------------------- */

import './index.scss'
import * as React from 'react'
import { Link } from 'react-router-dom'
import Button from 'components/common/Button'
import { TeamMemberType } from 'helpers/types'
import Facebook from './facebook.svg'

export interface ITeamHomepageSectionProps {
  teamMembers: TeamMemberType[]
  hideAllButton?: boolean
  largeList?: boolean
}

export default class TeamHomepageSection extends React.Component<
  ITeamHomepageSectionProps
> {
  get list() {
    return this.props.teamMembers.map((member, key) => (
      <TeamMember key={key} {...member} />
    ))
  }

  public render() {
    const { hideAllButton, largeList } = this.props
    return (
      <section
        className={`TeamHomepageSection ${largeList ? 'largeList' : ''}`}
      >
        <div className="top">
          <header>
            <h1>echipa</h1>
            <p>
              Fii fără griji - instructorii noștri sunt cei mai pregătiți și
              dornici să te ajute să treci peste orice dificultate.
            </p>
            {!hideAllButton && (
              <Link to="/desprenoi">
                <Button>Vezi toată echipa</Button>
              </Link>
            )}
          </header>
        </div>
        <div className="TeamList">{this.list}</div>
      </section>
    )
  }
}

type ITeamMemberProps = TeamMemberType & {}

const TeamMember: React.FunctionComponent<ITeamMemberProps> = props => {
  return (
    <div className="TeamMember">
      <img src={props.photo} alt={`${props.name} profile`} />
      {props.facebook && (
        <a href={props.facebook} className="facebook">
          <img alt="" src={Facebook} />
        </a>
      )}
      <h1>{props.name}</h1>
      <h2>{props.position}</h2>
    </div>
  )
}
