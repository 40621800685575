/* -------------------------------------------------------------------------- */
/*                      🗃 Miscellaneous useful variables                     */
/* -------------------------------------------------------------------------- */
import { Course, Package, ScheduleEntry, TeamMemberType } from './types'

export const COLORS = {
  green: '#35AF45',
  orange: '#FF8000',
  blue: '#00B1FF',
  darkblue: '#00008b',
  mauve: '#B400FF',
  yellow: '#f7df1e',
  gray: '#666666'
}

export const DEFAULT_COURSE_COLOR = COLORS.gray
export const SHADE_SHIFT_AMOUNT = -30

export const DUMMY_COURSES: Course[] = [
  {
    id: 0,
    type: 'ccna1',
    title: 'CCNA1',
    description:
      'Descoperă fundamentele rețelisticii și analizează circulația pachetelor prin stiva OSI!',
    price: 0,
    color: COLORS.orange
  },
  {
    id: 2,
    type: 'ccna2',
    title: 'CCNA2',
    description:
      'Află cum poți segmenta o rețea pentru a o securiza și optimiza!',
    price: 0,
    color: COLORS.green
  },
  {
    id: 3,
    type: 'ccna3',
    title: 'CCNA3',
    description:
      'Află tehnologiile bleeding edge de cloud și securitate în rețelistică!',
    price: 0,
    color: COLORS.blue
  },
  {
    id: 5,
    type: 'web1',
    title: 'Web 101',
    description:
      'Cunoaște browser-ul și fă primii pași în cariera ta de web developer!',
    price: 0,
    color: COLORS.yellow
  },
  {
    id: 6,
    type: 'web2',
    title: 'Web 102',
    description:
      'Descoperă cum funcționează paginile web dinamice și interacțiunea lor cu internetul',
    price: 0,
    color: COLORS.yellow
  },
  {
    id: 7,
    type: 'web3',
    title: 'Web 103',
    description:
      'Vezi cum poți aduce magie într-o aplicație web cu elemente audio, video și 3D!',
    price: 0,
    color: COLORS.yellow,
    isDisabled: true
  },
  {
    id: 8,
    type: 'python1',
    title: 'Python 101',
    description: '',
    price: 0,
    color: COLORS.darkblue
  },
  {
    id: 9,
    type: 'python2',
    title: 'Python 102',
    description: '',
    price: 0,
    color: COLORS.darkblue
  },
  {
    id: 10,
    type: 'python3',
    title: 'Python 103',
    description: '',
    price: 0,
    color: COLORS.darkblue
  }
]

export const DUMMY_PACKAGES: Package[] = DUMMY_COURSES.map(
  (course, id): Package[] => [
    {
      id: 3 * id + 1,
      price: 0,
      courses: [course]
    },
    {
      id: 3 * id + 2,
      price: 0,
      courses: [course, DUMMY_COURSES[2]]
    },
    {
      id: 3 * id + 3,
      price: 0,
      courses: [course, DUMMY_COURSES[3]]
    }
  ]
).reduce((prev: Package[], current: Package[]) => [...prev, ...current], [])

export const DUMMY_SCHEDULE_OPTIONS: ScheduleEntry[] = [
  {
    id: 0,
    startHour: 18,
    extraDaysOfWeek: null,
    endHour: 21,
    dayOfWeek: 3,
    instructors: 'Andrei Ionescu',
    room: 'EG202'
  },
  {
    id: 1,
    startHour: 18,
    extraDaysOfWeek: null,
    endHour: 21,
    dayOfWeek: 3,
    instructors: 'Andrei Ionescu',
    room: 'EG202'
  },
  {
    id: 2,
    startHour: 18,
    extraDaysOfWeek: null,
    endHour: 21,
    dayOfWeek: 3,
    instructors: 'Andrei Ionescu',
    room: 'EG202'
  }
]

export const DUMMY_TEAM_MEMBERS: TeamMemberType[] = [
  {
    name: 'Ion Popescu',
    photo: 'https://picsum.photos/300/300?random',
    position: 'Instructor',
    facebook: 'https://facebook.com'
  },
  {
    name: 'Ion Popescu',
    photo: 'https://picsum.photos/300/300?random',
    position: 'Instructor'
  },
  {
    name: 'Ion Popescu',
    photo: 'https://picsum.photos/300/300?random',
    position: 'Instructor'
  },
  {
    name: 'Ion Popescu',
    photo: 'https://picsum.photos/300/300?random',
    position: 'Instructor'
  },
  {
    name: 'Ion Popescu',
    photo: 'https://picsum.photos/300/300?random',
    position: 'Instructor'
  },
  {
    name: 'Ion Popescu',
    photo: 'https://picsum.photos/300/300?random',
    position: 'Instructor'
  },
  {
    name: 'Ion Popescu',
    photo: 'https://picsum.photos/300/300?random',
    position: 'Instructor'
  }
]
