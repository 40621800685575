/* -------------------------------------------------------------------------- */
/*                            🆘 HelpBox component                            */
/* -------------------------------------------------------------------------- */

import './index.scss'

/* ---------------------------- 🌍 Global imports --------------------------- */
import * as React from 'react'
import TextField from 'components/common/TextField'
import Button from 'components/common/Button'

/* ------------------------------ 🗿 Constants ------------------------------ */
const STEPS: IStepProps[] = [
  {
    id: 1,
    title: 'Alege clasa',
    description:
      'Din clasele de mai sus, alege clasa la care vrei să te înscrii'
  },
  {
    id: 2,
    title: 'Alege un pachet',
    description:
      'După ce îți alegi clasa, ai posibilitatea să îți alegi un pachet de cursuri ce include clasa ta, sau doar clasa aleasă de tine'
  },
  {
    id: 3,
    title: 'Îți alegi slot-ul și vii la clasă',
    description:
      'După ce te-ai hotărât la ce clasă vrei, alege ora care se pliază cel mai bine orarului tău. Vei primi un e-mail cu data la care încep orele.'
  }
]

export interface IHelpBoxProps {}

export default class HelpBox extends React.Component<IHelpBoxProps> {
  public render() {
    return (
      <section className="HelpBox">
        <div className="steps">
          <header>
            <h1>cum mă înscriu? </h1>
          </header>
          <div className="steps">{this.__steps}</div>
        </div>
        <div className="form">
          <header>
            <h1>need help ?</h1>
            <p>
              Orice problemă ai avea cu procesul de înscriere, suntem aici să te
              ajutăm. Folosește formularul sau plugin-ul de messenger ca să
              intri în legătură cu noi.
            </p>
          </header>
          <ContactForm />
        </div>
      </section>
    )
  }

  private get __steps() {
    return STEPS.map((step, key) => <Step key={key} {...step} />)
  }
}

interface IStepProps {
  id: number
  title: string
  description: string
}

const Step: React.FunctionComponent<IStepProps> = props => {
  return (
    <div className="step">
      <h1 className="id">{props.id}</h1>
      <div className="details">
        <h1>{props.title}</h1>
        <p>{props.description}</p>
      </div>
    </div>
  )
}

interface IContactFormProps {}

const ContactForm: React.FunctionComponent<IContactFormProps> = props => {
  return (
    <div className="ContactForm">
      <TextField onChange={() => {}} label="Nume" value="" />
      <TextField onChange={() => {}} label="E-mail" value="" />
      <TextField onChange={() => {}} label="Mesaj" value="" isTextArea />
      <div className="buttonWrapper">
        <Button isLight onClick={() => {}}>
          Trimite
        </Button>
      </div>
    </div>
  )
}
