/**
 * 📚 Courses homepage section
 */

import './index.scss'

import { COLORS } from 'helpers/variables'
import * as React from 'react'

import Button from 'components/common/Button'
import CourseBox, { ICourseBoxProps } from 'components/common/CourseBox'
import { Link } from 'react-router-dom'

export interface ICoursesProps { }

const COURSES: ICourseBoxProps[] = [
  {
    backgroundColor: '#999999',
    title: 'Cursurile CCNA',
    description:
      'De la rețeaua de acasă până la infrastructura internetului, învată cum funcționează Internetul',
    options: [
      {
        text: '1',
        color: COLORS.orange
      },
      {
        text: '2',
        color: COLORS.green
      },
      {
        text: '3',
        color: COLORS.blue
      }
    ]
  },
  {
    backgroundColor: '#999999',
    title: 'Web',
    description: 'Descoperă secretele din spatele browserului',
    options: [
      {
        text: '101',
        color: COLORS.yellow
      },
      {
        text: '102',
        color: COLORS.yellow
      },
      {
        text: '103',
        color: COLORS.yellow
      }
    ]
  },
  {
    backgroundColor: '#999999',
    title: 'Python',
    description: 'O unealtă indispensabilă în arsenalul tău de cunoștințe',
    options: [
      {
        text: '101',
        color: COLORS.blue
      },
      {
        text: '102',
        color: COLORS.blue
      },
      {
        text: '103',
        color: COLORS.blue
      }
    ]
  }
]

export default class Courses extends React.Component<ICoursesProps> {
  public render() {
    return (
      <section className='HomepageCourses'>
        <header>
          <h1>cursuri</h1>
          <p>
            De la urmărirea unui pachet prin Internet la crearea unui joc Snake
            prin tehnologii web și Python (limbajul, nu șarpele), cursurile
            CCNA.ro/Hackademy îți dau instrumentele pentru a vedea lumile
            digitale cu alți ochi!
          </p>
          <Link to='/cursuri'>
            <Button>Vezi toate cursurile</Button>
          </Link>
        </header>
        <div className='courseBoxes'>
          {COURSES.map((course, key) => (
            <Link
              to={`/cursuri#${
                course.title.includes('Web')
                  ? 'webdev'
                  : course.title.includes('Python')
                    ? 'python'
                    : 'ccna'
                }`}
            >
              <CourseBox {...course} key={key} showPrice={false} />
            </Link>
          ))}
        </div>
      </section>
    )
  }
}
