/* -------------------------------------------------------------------------- */
/*                           📚 Package Box display                           */
/* -------------------------------------------------------------------------- */

import './index.scss'

/* ---------------------------- 🌍 Global imports --------------------------- */
import * as React from 'react'

/* ---------------------------- 🤝 Common imports --------------------------- */
import CourseBox from '../CourseBox'
import Button from '../Button'

/* -------------------------- 🗿 Constants & types -------------------------- */
import { Package } from 'helpers/types'
import { DEFAULT_COURSE_COLOR } from 'helpers/variables'

type IPackageBoxProps = Package & {
  onClick?: () => void
}

export default class PackageBox extends React.Component<IPackageBoxProps> {
  public render() {
    const { courses, price, onClick } = this.props

    return (
      <div className="PackageBox">
        <div className="options">
          {this.__courses}
          {courses.length > 1 ? <div className="plus">+</div> : null}
        </div>
        {onClick && (
          <div className="price">
            <span>{price} RON</span>
            <Button onClick={onClick} isLight>
              Cumpără
            </Button>
          </div>
        )}
      </div>
    )
  }

  get __courses() {
    const { courses } = this.props
    return courses.map((course, key) => (
      <CourseBox
        key={key}
        title={course.title}
        description={course.description}
        backgroundColor={course.color ? course.color : DEFAULT_COURSE_COLOR}
      />
    ))
  }
}
