/**
 * 🆗 Button component
 *
 * * Basic, generic button component with provided styles
 */

import './index.scss'

import * as React from 'react'
import { ButtonHTMLAttributes } from 'react'

interface IButtonProps extends ButtonHTMLAttributes<any> {
  children: React.ReactChild
  isFilled?: boolean
  isLight?: boolean
  subtitle?: string
}

function getClassName(props: IButtonProps): string {
  let className = ''

  if (props.isFilled) {
    className += ' filled'
  }
  if (props.isLight) {
    className += ' light'
  }

  return className
}

const Button: React.FunctionComponent<IButtonProps> = props => {
  return (
    <button
      className={getClassName(props)}
      data-subtitle={props.subtitle}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  )
}

export default Button
