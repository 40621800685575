/* -------------------------------------------------------------------------- */
/*                        💰 Payment Confirmation Page                        */
/* -------------------------------------------------------------------------- */

import './index.scss'
import * as React from 'react'
import Header from 'components/Header'
import CourseBox from 'components/common/CourseBox'
import { DUMMY_COURSES } from 'helpers/variables'
import ScheduleEntryBox from 'components/common/ScheduleEntryBox'
import ContactHomepageSection from 'components/Home/components/Contact'
import { Course, ScheduleEntry } from 'helpers/types'
import Axios from 'axios'

export interface IPaymentConfirmationPageProps {}
export interface IPaymentConfirmationPageState {
  selectedClass: Course | null
  schedule: ScheduleEntry | null
  isSuccess: boolean
  isPaid: boolean
  isRefused: boolean
}

export default class PaymentConfirmationPage extends React.Component<
  IPaymentConfirmationPageProps,
  IPaymentConfirmationPageState
> {
  state: IPaymentConfirmationPageState = {
    selectedClass: null,
    schedule: null,
    isSuccess: true,
    isPaid: false,
    isRefused: false
  }

  async componentDidMount() {
    const transaction = window.location.href.split('orderId=')[1]
    try {
      const response: any = await Axios.get(`/api/getLogEntry/${transaction}`)

      this.setState({
        selectedClass: DUMMY_COURSES.find(
          course => course.type === response.data.type
        ) as Course,
        schedule: {
          dayOfWeek: response.data.schedule.dayOfWeek,
          startHour: response.data.schedule.startHour,
          extraDaysOfWeek: response.data.schedule.extraDaysOfWeek,
          endHour: response.data.schedule.endHour,
          id: response.data.schedule.id,
          room: response.data.schedule.room,
          instructors: response.data.schedule.instructors.split(', ')
        },
        isSuccess: true,
        isPaid: response.data.isPaid,
        isRefused: response.data.isRefused
      })
    } catch (e) {
      this.setState({ isSuccess: false })
    }
  }

  get paymentFailureMessage() {
    return (
      <section className='PaymentConfirmation failure'>
        <header>
          <h1>hopa!</h1>
          <p>A apărut o eroare la procesarea plății tale</p>
        </header>
        <div className='content'>
          <p>
            Încearcă să efectuezi plata din nou. Dacă ai probleme,
            contactează-ne pe Facebook și îți vom răspunde în cel mai scurt
            timp.
          </p>
        </div>
      </section>
    )
  }

  get paymentRefusedMessage() {
    return (
      <section className='PaymentConfirmation refused'>
        <header>
          <h1>hopa!</h1>
          <p>Plata ta a fost refuzată</p>
        </header>
        <div className='content'>
          <p>
            Plata ta a fost refuzată. Încearcă să te înregistrezi din nou, sau
            contactează-ne pe Facebook dacă întâmpini în continuare probleme.
          </p>
        </div>
      </section>
    )
  }

  get paymentPendingMessage() {
    if (this.state.schedule !== null && this.state.selectedClass !== null) {
      const { selectedClass } = this.state
      return (
        <section className='PaymentConfirmation'>
          <header>
            <h1>plată în procesare</h1>
            <p>Înscrierea ta la clasa de {selectedClass.title} este în curs</p>
          </header>
          <div className='content'>
            <CourseBox
              backgroundColor={selectedClass.color || '#333'}
              description={selectedClass.description}
              title={selectedClass.title}
            />

            <p>
              Înscrierea ta la clasă este în curs. Fii fără griji, vei fi
              notificat de îndată ce plata este confirmată. Pentru orice
              nelămuriri, nu ezita să ne contactezi pe e-mail sau Facebook.
            </p>

            <ScheduleEntryBox {...this.state.schedule} />
          </div>
        </section>
      )
    }
    return null
  }

  get paymentSuccessMessage() {
    if (this.state.schedule !== null && this.state.selectedClass !== null) {
      const { selectedClass } = this.state
      return (
        <section className='PaymentConfirmation paid'>
          <header>
            <h1>succes!</h1>
            <p>Te-ai înscris la clasa de {selectedClass.title}</p>
          </header>
          <div className='content'>
            <CourseBox
              backgroundColor={selectedClass.color || '#333'}
              description={selectedClass.description}
              title={selectedClass.title}
            />

            <p>
              Te-ai inscris la clasa de {selectedClass.title}, semestrul de
              toamnă. Clasele încep din data de{' '}
              {selectedClass.title.includes('ccna') ? '21' : '7'} octombrie
              2019. Orice nelămuriri ai, ne poți contacta atât prin e-mail cât
              și pe messenger.
            </p>

            <ScheduleEntryBox {...this.state.schedule} />
          </div>
        </section>
      )
    }
    return null
  }

  get content() {
    const { selectedClass, isSuccess, isPaid, isRefused } = this.state

    if (selectedClass === null) {
      return <div className='loading'>Se încarcă...</div>
    }

    if (isSuccess) {
      if (isPaid) {
        return this.paymentSuccessMessage
      } else if (isRefused) {
        return this.paymentRefusedMessage
      } else {
        return this.paymentPendingMessage
      }
    } else {
      return this.paymentFailureMessage
    }
  }

  public render() {
    return (
      <>
        <Header isCustomHeader type='courses' />
        {this.content}
        <ContactHomepageSection />
      </>
    )
  }
}
