/**
 * 📗 CourseBox
 *
 * * Provides a versatile box that can show multiple states
 *
 * ? Options include:
 * ? * BaseBox: just title and text (e.g. for signup options)
 * ? * OptionsBox: BaseBox + course options (e.g. for homepage)
 * ? * PriceBox: BaseBox + price (e.g. for course listing)
 */

import * as React from 'react'
import BaseBox from './components/BaseBox'
import BoxOptions from './components/BoxOptions'

type BaseCourseBoxProps = {
  title: string
  description: string
  backgroundColor: string
  options?: { text: string; color: string }[]
}

export type ICourseBoxProps =
  | BaseCourseBoxProps & {
      showPrice: true
      onRegister: () => void
      price: number
    }
  | BaseCourseBoxProps & {
      showPrice?: false
    }

export default class CourseBox extends React.Component<ICourseBoxProps, any> {
  get logo(): 'ccna' | 'web' | 'python' {
    const { title } = this.props

    if (title.toLowerCase().includes('ccna')) {
      return 'ccna'
    } else if (title.toLowerCase().includes('web')) {
      return 'web'
    } else {
      return 'python'
    }
  }

  /**
   * BaseBox props that vary based on CourseBox props
   */
  get conditionalProps() {
    if (this.props.showPrice === true) {
      return {
        price: this.props.price,
        onRegister: this.props.onRegister,
        showCta: true
      } as {
        price: number
        onRegister: () => void
        showCta: true
      }
    } else {
      return {}
    }
  }

  /**
   * Generate options if prop present
   */
  get options() {
    const { options } = this.props

    if (!options) {
      return ''
    }

    return <BoxOptions options={options} />
  }

  public render() {
    const { title, description, backgroundColor } = this.props

    return (
      <BaseBox
        title={title}
        description={description}
        backgroundColor={backgroundColor}
        backgroundLogo={this.logo}
        {...this.conditionalProps}
      >
        {this.options}
      </BaseBox>
    )
  }
}
