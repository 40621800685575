/* -------------------------------------------------------------------------- */
/*                         📚 Package options listing                         */
/* -------------------------------------------------------------------------- */

import './index.scss'
import * as React from 'react'

/* -------------------------- 🤝 Common components -------------------------- */
import PackageBox from 'components/common/PackageBox'

/* -------------------------- 🗿 Types & Constants -------------------------- */
import { Course, Package } from 'helpers/types'
import { DEFAULT_COURSE_COLOR } from 'helpers/variables'
import { shiftColor } from 'helpers/util'

export interface IPackageOptionsProps {
  course: Course
  packages: Package[]
  onPackageSelect: (selectedPackage: Package) => void
}

export default class PackageOptions extends React.Component<
  IPackageOptionsProps
> {
  public render() {
    const { course } = this.props

    return (
      <section className="PackageOptions" style={this.__style}>
        <header>
          <h1>înscriere la {course.title}</h1>
          <p>Alege pachetul dorit</p>
        </header>
        <div className="options">{this.__packages}</div>
      </section>
    )
  }

  private get __style(): React.CSSProperties {
    const { course } = this.props
    const primaryColor = course.color || DEFAULT_COURSE_COLOR
    const secondaryColor = shiftColor(primaryColor)

    return {
      background: `linear-gradient(to top right, ${primaryColor}, ${secondaryColor})`
    }
  }

  private get __packages() {
    const { packages, onPackageSelect } = this.props

    return packages.map((pack, key) => (
      <PackageBox key={key} onClick={() => onPackageSelect(pack)} {...pack} />
    ))
  }
}
