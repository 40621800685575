/**
 * 🏠 Home page component
 *
 * * Renders the homepage main components and manages UI state
 */

import * as React from 'react'

import Header from 'components/Header'
import Courses from 'components/Home/components/Courses'
import Projects from 'components/Home/components/Projects'
import ContactHomepageSection from 'components/Home/components/Contact'
//import TeamHomepageSection from 'components/Home/components/Team'
//import { DUMMY_TEAM_MEMBERS } from 'helpers/variables'
import { PROJECTS } from 'components/Home/components/Projects/projects'

export interface IHomePageProps {}

export default class HomePage extends React.Component<IHomePageProps, any> {
  public render() {
    return (
      <>
        <Header />
        <Courses />
        <Projects projects={PROJECTS} />
        {/*<TeamHomepageSection teamMembers={DUMMY_TEAM_MEMBERS} />*/}
        <ContactHomepageSection />
      </>
    )
  }
}
