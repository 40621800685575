/* -------------------------------------------------------------------------- */
/*                           🤵 Team members listing                          */
/* -------------------------------------------------------------------------- */
import { TeamMemberType } from "../../helpers/types";

/* ----------------------------- 📷 User photos ----------------------------- */

import RaduPetean from "./photos/Radu Petean.png";
import MihaiChiroiu from "./photos/Mihai Chiroiu.jpg";
import FlaviaOprea from "./photos/Flavia Oprea.jpg";
import CristianaTrifu from "./photos/Cristiana Trifu.jpg";
import AlexandruSelea from "./photos/Alexandru Selea.jpg";
import AlinRoșca from "./photos/Alin Roșca.png";
import AnaMariaCălinescu from "./photos/Ana-Maria Călinescu.png";
import AndreiBîrzîche from "./photos/Andrei Bîrzîche.jpg";
import AndreiRăduță from "./photos/Andrei Răduță.jpg";
import CătălinGhenea from "./photos/Cătălin Ghenea.jpg";
import CristiDuțescu from "./photos/Cristi Duțescu.jpg";
import ȘerbanCozma from "./photos/Șerban Cozma.jpg";
import DănuțGabrielMatei from "./photos/Dănuț-Gabriel Matei.png";
import EbruResul from "./photos/Ebru Resul.jpg";
import IoanMihailStan from "./photos/Ioan-Mihail Stan.jpg";
import IoanaChirca from "./photos/Ioana Chirca.jpg";
import LaurențiuBotezatu from "./photos/Laurențiu Botezatu.jpg";
import MihaiOlaru from "./photos/Mihai Olaru.jpg";
import RaduJinga from "./photos/Radu Jinga.jpg";
import RaduNicolau from "./photos/Radu Nicolau.jpg";
import RuxandraChiroiu from "./photos/Ruxandra Chiroiu.jpg";
import TudorCebere from "./photos/Tudor Cebere.jpg";
import TudorManea from "./photos/Tudor Manea.jpg";
import VictorCiurel from "./photos/Victor Ciurel.jpg";
import VladȚurcanu from "./photos/Vlad Țurcanu.jpg";
import MariusTudosoiu from "./photos/Marius Tudosoiu.jpg";
import AndreeaStrejovici from "./photos/Andreea Strejovici.jpg";
import AndreiSerițan from "./photos/Andrei Serițan.jpg";
import VladCostache from "./photos/Vlad Costache.jpg";
import IonuțEpure from "./photos/Ionuț Epure.png";

export const TEAM_MEMBERS: TeamMemberType[] = [
  {
    name: "Radu Petean",
    position: "Senior Instructor",
    photo: RaduPetean
  },
  {
    name: "Mihai Chiroiu",
    position: "Senior Instructor",
    photo: MihaiChiroiu
  },
  {
    name: "Flavia Oprea",
    position: "Senior Instructor",
    photo: FlaviaOprea
  },
  {
    name: "Cristiana Trifu",
    position: "Instructor",
    photo: CristianaTrifu
  },
  {
    name: "Alexandru Selea ",
    position: "Junior Instructor ",
    photo: AlexandruSelea
  },
  {
    name: "Alin Roșca",
    position: "Junior Instructor ",
    photo: AlinRoșca
  },
  {
    name: "Ana-Maria Călinescu",
    position: "Junior Instructor ",
    photo: AnaMariaCălinescu
  },
  {
    name: "Andrei Bîrzîche",
    position: "Junior Instructor ",
    photo: AndreiBîrzîche
  },
  {
    name: "Andrei Răduță",
    position: "Junior Instructor ",
    photo: AndreiRăduță
  },
  {
    name: "Cătălin Ghenea",
    position: "Instructor",
    photo: CătălinGhenea
  },
  {
    name: "Cristi Duțescu",
    position: "Instructor",
    photo: CristiDuțescu
  },
  {
    name: "Șerban Cozma",
    position: "Junior Instructor ",
    photo: ȘerbanCozma
  },
  {
    name: "Dănuț-Gabriel Matei",
    position: "Junior Instructor ",
    photo: DănuțGabrielMatei
  },
  {
    name: "Ebru Resul",
    position: "Junior Instructor ",
    photo: EbruResul
  },
  {
    name: "Ioan-Mihail Stan",
    position: "Senior Instructor",
    photo: IoanMihailStan
  },
  {
    name: "Ioana Chirca",
    position: "Instructor",
    photo: IoanaChirca
  },
  {
    name: "Laurențiu Botezatu",
    position: "Instructor",
    photo: LaurențiuBotezatu
  },
  {
    name: "Mihai Olaru",
    position: "Junior Instructor ",
    photo: MihaiOlaru
  },
  {
    name: "Radu Jinga",
    position: "Instructor",
    photo: RaduJinga
  },
  {
    name: "Radu Nicolau",
    position: "Instructor",
    photo: RaduNicolau
  },
  {
    name: "Ruxandra Chiroiu",
    position: "Senior Instructor",
    photo: RuxandraChiroiu
  },
  {
    name: "Tudor Cebere",
    position: "Junior Instructor ",
    photo: TudorCebere
  },
  {
    name: "Tudor Manea",
    position: "Junior Instructor ",
    photo: TudorManea
  },
  {
    name: "Victor Ciurel",
    position: "Instructor",
    photo: VictorCiurel
  },
  {
    name: "Vlad Țurcanu",
    position: "Instructor",
    photo: VladȚurcanu
  },
  {
    name: "Marius Tudosoiu",
    position: "Junior Instructor ",
    photo: MariusTudosoiu
  },
  {
    name: "Andreea Strejovici",
    position: "Junior Instructor ",
    photo: AndreeaStrejovici
  },
  {
    name: "Andrei Serițan",
    position: "Junior Instructor ",
    photo: AndreiSerițan
  },
  {
    name: "Vlad Costache",
    position: "Junior Instructor ",
    photo: VladCostache
  },
  {
    name: "Ionuț Epure",
    position: "Junior Instructor ",
    photo: IonuțEpure
  }
];
