/* -------------------------------------------------------------------------- */
/*                            ℹ Footer information                            */
/* -------------------------------------------------------------------------- */

import './index.scss'
import * as React from 'react'
import Logo from 'assets/Logo'

import netopia from '../../../components/Courses/ScheduleOptions/netopia.jpg'

export interface IFooterInfoProps {}

export default class FooterInfo extends React.Component<IFooterInfoProps> {
  public render() {
    return (
      <div className='FooterInfo'>
        <Logo />

        <img src={netopia} alt='' />
        <div className='content'>
          <p>
            Asociația Tech Lounge CIF 31755999 Universitatea Politehnica
            București, str. Splaiul Independenței nr. 313, sala EG202, sectorul
            6, București
          </p>
          <a href='/termeni.pdf'>Termeni de utilizare ai site-ului</a>
        </div>
      </div>
    )
  }
}
