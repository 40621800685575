/* -------------------------------------------------------------------------- */
/*                         💌 Useful Contact variables                        */
/* -------------------------------------------------------------------------- */

import { IContactInfoEntryProps } from '.'

/* -------------------------------- 📷 Images ------------------------------- */
import Facebook from './assets/Facebook.svg'
import Insta from './assets/Insta.svg'
import Location from './assets/Location.svg'
import Mail from './assets/Mail.svg'

export const CONTACT_INFO: IContactInfoEntryProps[] = [
  {
    photo: Facebook,
    text: '/ccna.ro'
  },
  {
    photo: Location,
    text: 'Splaiul Independenței nr.14, Sala EG202'
  },
  {
    photo: Mail,
    text: 'contact@ccna.ro'
  },
  {
    photo: Insta,
    text: '@ccnaro'
  }
]
