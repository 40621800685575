/**
 * 📢 Announcement component
 *
 * * Provides a button and text display
 * * if an announcement is presented
 */

import './index.scss'

import * as React from 'react'

import Button from 'components/common/Button'
import { Link } from 'react-router-dom'

// 🧱 Constants
const DEFAULT_BUTTON_TEXT = 'Vezi clasele'

export interface IAnnouncementProps {
  text: string
  buttonAction: () => void
  buttonText?: string
}

export default class Announcement extends React.Component<
  IAnnouncementProps,
  any
> {
  get buttonText(): string {
    if (this.props.buttonText) {
      return this.props.buttonText
    } else {
      return DEFAULT_BUTTON_TEXT
    }
  }

  public render() {
    return (
      <div className='Announcement'>
        <p>{this.props.text}</p>
        <Link to='/cursuri'>
          <Button isFilled onClick={this.props.buttonAction}>
            {this.buttonText}
          </Button>
        </Link>
      </div>
    )
  }
}
