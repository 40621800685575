/**
 * 📗 BaseBox
 *
 * * Provides the base for the course box.
 * * Displays title and description, and optional extra footer content
 */

import './index.scss'

import { shiftColor } from 'helpers/util'
import * as React from 'react'
import CCNAWatermark from './assets/CCNAWatermark.svg'
// 🎨 Watermarks
import JSWatermark from './assets/JSWatermark.svg'
import PyWatermark from './assets/PyWatermark.svg'

import Button from 'components/common/Button'

type BaseProps = {
  title: string;
  description: string;
  backgroundColor: string;
  backgroundLogo: 'ccna' | 'web' | 'python';
  children?: React.ReactChild;
};

export type IBaseBoxProps =
  | BaseProps & {
      showCta?: false;
    }
  | BaseProps & {
      showCta: true;
      price: number;
      onRegister: () => void;
    };

export default class BaseBox extends React.Component<IBaseBoxProps, any> {
  get style(): React.CSSProperties {
    const secondaryColor = this.props.backgroundColor;
    const primaryColor = shiftColor(secondaryColor);

    return {
      background: `linear-gradient(to top right, ${primaryColor}, ${secondaryColor})`
    };
  }

  get watermark() {
    const { backgroundLogo } = this.props;

    switch (backgroundLogo) {
      case 'ccna':
        return CCNAWatermark;
      case 'python':
        return PyWatermark;
      case 'web':
        return JSWatermark;
      default:
        return '';
    }
  }

  get icon() {
    return null;
    // return <div className="icon" />;
  }

  get price() {
    if (!this.props.showCta) {
      return null;
    }

    const { price } = this.props;

    if (price === 0) {
      return null;
    }

    return <span className="price">{price} RON</span>;
  }

  get cta() {
    if (!this.props.showCta) {
      return null;
    }
    const { onRegister } = this.props;

    return (
      <div className="cta">
        {this.price}
        <Button isLight onClick={onRegister}>
          Înscrie-te
        </Button>
      </div>
    );
  }

  public render() {
    const { children, title, description } = this.props;

    return (
      <div className="BaseBox" style={this.style}>
        <img className="watermark" src={this.watermark} alt="watermark" />
        <div className="content">
          <h1>
            {this.icon}
            {title}
          </h1>
          <p>{description}</p>
        </div>
        {this.cta}
        {children}
      </div>
    );
  }
}
