/* -------------------------------------------------------------------------- */
/*                       💰 Payment Confirmation Router                       */
/* -------------------------------------------------------------------------- */

import * as React from 'react'
import PaymentConfirmationPage from 'pages/PaymentConfirmation'

export interface IPaymentConfirmationRouterProps {}

export default class PaymentConfirmationRouter extends React.Component<
  IPaymentConfirmationRouterProps
> {
  public render() {
    return <PaymentConfirmationPage />
  }
}
