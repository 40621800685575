/* -------------------------------------------------------------------------- */
/*                             🔝 Header component                            */
/* -------------------------------------------------------------------------- */

/**
 * Displays header title and image
 * Also displays page menu
 */

import './index.scss'

import * as React from 'react'

import Announcement from 'components/Header/components/Announcement'
import Menu from 'components/Header/components/Menu'
import TitleScroller from 'components/Header/components/TitleScroller'
import { Keyword, KEYWORDS } from 'components/Header/keywords'

/* ------------------------- 📷 Custom header images ------------------------ */
import CoursesImage from './assets/images/courses.png'

/* ------------------------------ 🧱 Constants ------------------------------ */
const ANNOUNCEMENT_TEXT = `
Noi clase de web și Python din 17 februarie 2020!
Noi clase de CCNA din 29 februarie 2020!
`

export type IHeaderProps =
  | {
    isCustomHeader?: false
    type?: any
  }
  | {
    isCustomHeader: true
    type: 'aboutus' | 'projects' | 'courses'
  }

export interface IHeaderState {
  currentKeyword: Keyword
  nextKeyword: Keyword
  isSwitching: boolean
}

export default class Header extends React.Component<
  IHeaderProps,
  IHeaderState
  > {
  state: IHeaderState = {
    currentKeyword: KEYWORDS[0],
    nextKeyword: KEYWORDS[1],
    isSwitching: false
  }

  interval: number = 0

  componentDidMount() {
    this.interval = window.setInterval(this.switchKeyword, 6000)
  }

  get images() {
    const { currentKeyword } = this.state
    const { isCustomHeader, type } = this.props

    if (isCustomHeader) {
      let image: string

      switch (type) {
        case 'courses':
          image = CoursesImage
          break
        default:
          image = CoursesImage
      }

      return <img src={image} alt='header' />
    }

    return KEYWORDS.map(keyword => (
      <img
        key={keyword.id}
        src={keyword.image}
        alt={keyword.word}
        className={`
        ${currentKeyword.id === keyword.id ? 'active' : ''}
      `}
      />
    ))
  }

  switchKeyword = () => {
    const { currentKeyword } = this.state

    if (currentKeyword.id < KEYWORDS.length - 1) {
      this.setState({ currentKeyword: KEYWORDS[currentKeyword.id + 1] })
    } else {
      this.setState({ currentKeyword: KEYWORDS[0] })
    }
  }

  public render() {
    const { isCustomHeader } = this.props

    return (
      <header key='pageHeader' className={`${isCustomHeader ? 'small' : ''} `}>
        <div className={`images ${isCustomHeader ? 'disabled' : ''}`}>
          {this.images}
        </div>
        <div className='content'>
          <Menu />
          {this.__headerContent}
        </div>
      </header>
    )
  }

  private get __headerContent() {
    const { currentKeyword } = this.state
    const { isCustomHeader, type } = this.props

    if (isCustomHeader) {
      let title: string

      switch (type) {
        case 'courses':
          title = 'cursuri'
          break
        case 'aboutus':
          title = 'despre noi'
          break
        case 'projects':
          title = 'proiecte'
          break
        default:
          title = 'ccna.ro'
      }

      return <TitleScroller text={title} />
    }

    return (
      <>
        <TitleScroller selected={currentKeyword.word} />
        <Announcement buttonAction={() => { }} text={ANNOUNCEMENT_TEXT} />
      </>
    )
  }
}
