/* -------------------------------------------------------------------------- */
/*                       🔤 Generic TextField component                       */
/* -------------------------------------------------------------------------- */

/* -------------------------------- 🎨 Styles ------------------------------- */
import './index.scss'
import '@material/react-text-field/dist/text-field.min.css'

import ReactTextField, { Input } from '@material/react-text-field'

import * as React from 'react'

export interface ITextFieldProps {
  label: string
  value: string
  type?: string
  isTextArea?: boolean
  onChange: (value: string) => void
}

export default class TextField extends React.Component<ITextFieldProps> {
  public render() {
    const { label, value, isTextArea, onChange } = this.props
    const className = `ccnaTextInputElement ${isTextArea ? 'textArea' : ''}`
    return (
      <ReactTextField
        label={label}
        textarea={isTextArea}
        className={className}
        dense
      >
        <Input
          value={value}
          onChange={(e: React.FormEvent<HTMLInputElement>) => onChange(e.currentTarget.value)}
          type={this.props.type || 'text'}
        />
      </ReactTextField>
    )
  }
}
