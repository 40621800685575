import './index.scss'

import Logo from 'assets/Logo'
import * as React from 'react'
import ReactTypingEffect from 'react-typing-effect'

export type ITitleScrollerProps = {
  selected?: string
  text?: string
}

export default class TitleScroller extends React.Component<
  ITitleScrollerProps,
  any
> {
  public render() {
    const { selected, text } = this.props
    return (
      <div className="TitleScroller">
        <div className="row">
          <div className="logo">
            <Logo />
          </div>
          <h1>{selected ? 'Never stop' : text}</h1>
        </div>
        {selected && (
          <div className="row words">
            <ReactTypingEffect text={selected} eraseDelay={2000} speed={100} />
          </div>
        )}
      </div>
    )
  }
}
