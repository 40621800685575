/* -------------------------------------------------------------------------- */
/*                                 👋 About Us                                */
/* -------------------------------------------------------------------------- */

import * as React from 'react'
import AboutUsPage from 'pages/AboutUs'

export interface IAboutUsPageRouterProps {}

export default class AboutUsPageRouter extends React.Component<
  IAboutUsPageRouterProps
> {
  public render() {
    return <AboutUsPage />
  }
}
