import React from 'react'

const Logo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={155.269} height={101.112} {...props}>
    <g data-name="Header Logo" fill={props.fill || '#fff'}>
      <path
        data-name="Path 1"
        d="M80.455.007c1.454.581 2.941 1.083 4.334 1.783a2.124 2.124 0 0 1 1.04 1.478 47.656 47.656 0 0 1-1.355 14.807 15.958 15.958 0 0 1-1.978 4.449c-2.653 3.911-7.725 3.657-9.686-.605a25.774 25.774 0 0 1-2.09-7.784c-.387-3.468-.16-7-.275-10.5a2.011 2.011 0 0 1 1.5-2.144C73.306.982 74.672.498 76.038.003l4.418.007"
        fillRule="evenodd"
      />
      <path
        data-name="Path 2"
        d="M128.679 24.526c.308 1.593-.616 2.71-2.075 3.8-8.657 6.452-17.249 13-25.859 19.511-1.751 1.33-3.625 1.636-4.636.747-1.146-1.006-1.055-3.072.223-4.915 6.337-9.148 12.694-18.289 18.986-27.473a2.63 2.63 0 0 1 2.651-1.364 28.652 28.652 0 0 1 3.857.5c4.7 1.146 6.888 4.04 6.852 9.193"
        fillRule="evenodd"
      />
      <path
        data-name="Path 3"
        d="M27.06 24.305c-.016-5.468 2.939-8.668 8.079-9.452 2.948-.45 4.818.236 6.56 2.9 5.754 8.792 11.837 17.369 17.778 26.037a4.016 4.016 0 0 1 .812 1.627c.16 1.233.268 2.658-1.067 3.315a3.638 3.638 0 0 1-3.956-.687q-7.21-5.508-14.427-11c-3.911-2.979-7.784-6.02-11.765-8.9-1.49-1.083-2.311-2.264-2.014-3.837"
        fillRule="evenodd"
      />
      <path data-name="Path 4" d="M73.02 75.288L7.691 54.978s-14.2 6.679-4.012 20.2z" fillRule="evenodd" />
      <path data-name="Path 5" d="M82.217 75.297l65.4-20.107s14.169 6.722 3.943 20.217z" fillRule="evenodd" />
      <path
        data-name="Path 6"
        d="M10.761 90.454a11.008 11.008 0 0 1 .734-3.965 9.7 9.7 0 0 1 2.133-3.331 10.281 10.281 0 0 1 3.47-2.293 12.232 12.232 0 0 1 4.755-.855 11.46 11.46 0 0 1 6.148 1.542 9.868 9.868 0 0 1 3.693 3.954l-7.093 2.133a3.24 3.24 0 0 0-2.8-1.5 3.3 3.3 0 0 0-2.529 1.128 5.469 5.469 0 0 0-.011 6.4 3.307 3.307 0 0 0 2.529 1.134 3.234 3.234 0 0 0 2.8-1.49l7.086 2.156a9.871 9.871 0 0 1-3.7 3.943 11.491 11.491 0 0 1-6.155 1.523 12.245 12.245 0 0 1-4.751-.871 10.272 10.272 0 0 1-3.463-2.3 9.718 9.718 0 0 1-2.122-3.337 10.969 10.969 0 0 1-.722-3.968"
      />
      <path
        data-name="Path 7"
        d="M32.644 90.488a11.008 11.008 0 0 1 .734-3.965 9.7 9.7 0 0 1 2.133-3.331 10.318 10.318 0 0 1 3.47-2.3 12.314 12.314 0 0 1 4.755-.855 11.479 11.479 0 0 1 6.148 1.544 9.861 9.861 0 0 1 3.693 3.951l-7.093 2.136a3.24 3.24 0 0 0-2.8-1.5 3.305 3.305 0 0 0-2.53 1.128 5.469 5.469 0 0 0-.011 6.4 3.307 3.307 0 0 0 2.53 1.134 3.234 3.234 0 0 0 2.8-1.49l7.087 2.156a9.847 9.847 0 0 1-3.7 3.94 11.442 11.442 0 0 1-6.155 1.524 12.239 12.239 0 0 1-4.75-.869 10.273 10.273 0 0 1-3.463-2.3 9.717 9.717 0 0 1-2.122-3.337 10.961 10.961 0 0 1-.722-3.968"
      />
      <path
        data-name="Path 8"
        d="M76.086 100.634l-7.282-.011.018-11.344a2.975 2.975 0 0 0-.666-2.167 2.134 2.134 0 0 0-1.553-.671 3.239 3.239 0 0 0-1.955.8 4.786 4.786 0 0 0-1.535 2.066l-.019 11.307-7.282-.011.032-20.157 6.553.009v2.988a6.79 6.79 0 0 1 3.013-2.5 11.135 11.135 0 0 1 4.579-.855 6.191 6.191 0 0 1 3.276.751 5.246 5.246 0 0 1 1.836 1.861 6.885 6.885 0 0 1 .821 2.379 15.343 15.343 0 0 1 .187 2.3z"
      />
      <path
        data-name="Path 9"
        d="M85.556 101.033a7.8 7.8 0 0 1-2.8-.5 6.89 6.89 0 0 1-2.239-1.364 6.247 6.247 0 0 1-1.474-2.052 6.163 6.163 0 0 1-.531-2.568 5.985 5.985 0 0 1 .655-2.8 6.736 6.736 0 0 1 1.8-2.181 8.309 8.309 0 0 1 2.743-1.413 11.656 11.656 0 0 1 3.506-.493 9.435 9.435 0 0 1 4.062.772v-.615a2.6 2.6 0 0 0-.819-2.109 4.157 4.157 0 0 0-2.665-.693 8.818 8.818 0 0 0-3.18.551 15.643 15.643 0 0 0-3.069 1.622l-2.1-4.526a16.4 16.4 0 0 1 9.087-2.552q4.831.007 7.433 2.156t2.595 6.443l-.007 3.909a2.078 2.078 0 0 0 .382 1.438 2.106 2.106 0 0 0 1.38.482l-.011 6.132q-1 .19-1.877.283a14.96 14.96 0 0 1-1.573.1 5.189 5.189 0 0 1-2.968-.714 3.3 3.3 0 0 1-1.319-2.093l-.153-.727a9.264 9.264 0 0 1-3.107 2.619 8.171 8.171 0 0 1-3.756.9m2.192-5.21a4.523 4.523 0 0 0 1.341-.209 4.939 4.939 0 0 0 1.229-.554 3.049 3.049 0 0 0 .688-.669 1.237 1.237 0 0 0 .27-.746v-1.495a10.012 10.012 0 0 0-1.415-.392 7.2 7.2 0 0 0-1.377-.157 3.65 3.65 0 0 0-2.226.65 1.976 1.976 0 0 0-.884 1.645 1.685 1.685 0 0 0 .668 1.379 2.631 2.631 0 0 0 1.706.54"
      />
      <path data-name="Rectangle 1" d="M102.835 100.675l.011-7.051 5.901.01-.011 7.05z" />
      <path
        data-name="Path 10"
        d="M126.196 86.761a14.817 14.817 0 0 0-3.832.455 4.667 4.667 0 0 0-2.57 1.528l-.02 11.959-7.28-.011.032-20.159 6.668.011-.007 3.831a8.513 8.513 0 0 1 2.669-3.119 5.951 5.951 0 0 1 3.432-1.087 6.786 6.786 0 0 1 .92.041z"
      />
      <path
        data-name="Path 11"
        d="M138.206 101.112a12.223 12.223 0 0 1-4.768-.869 10.313 10.313 0 0 1-3.445-2.3 9.466 9.466 0 0 1-2.1-3.338 11.437 11.437 0 0 1 .011-7.933 9.49 9.49 0 0 1 2.113-3.333 10.336 10.336 0 0 1 3.452-2.293 13.561 13.561 0 0 1 9.523.016 10.218 10.218 0 0 1 3.464 2.3 9.476 9.476 0 0 1 2.1 3.337 11.45 11.45 0 0 1-.013 7.933 9.48 9.48 0 0 1-2.113 3.33 10.281 10.281 0 0 1-3.47 2.293 12.231 12.231 0 0 1-4.755.855m-3.547-10.467a4.7 4.7 0 0 0 .99 3.182 3.44 3.44 0 0 0 5.136.007 5.569 5.569 0 0 0 .011-6.362 3.444 3.444 0 0 0-5.135-.007 4.7 4.7 0 0 0-1 3.18"
      />
    </g>
  </svg>
)

export default Logo
