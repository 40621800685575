/* -------------------------------------------------------------------------- */
/*                            📅 Schedule Entry Box                           */
/* -------------------------------------------------------------------------- */

import './index.scss'
import * as React from 'react'
import { ScheduleEntry } from 'helpers/types'
import Button from '../Button'

const DAYS = [
  '',
  'Luni',
  'Marți',
  'Miercuri',
  'Joi',
  'Vineri',
  'Sâmbătă',
  'Duminică'
]

export type IScheduleEntryBoxProps = ScheduleEntry & {
  isSelected?: boolean
  onClick?: (id: number) => void
}

export default class ScheduleEntryBox extends React.Component<
  IScheduleEntryBoxProps
  > {
  public render() {
    const { room, isSelected } = this.props

    return (
      <div className={this.__className}>
        <span>în fiecare</span>
        <h1>{this.__scheduleTime}</h1>
        <span>Instructori: {this.props.instructors}</span>
        <span>Sala {room}</span>
        {this.props.onClick && (
          <Button isLight isFilled={isSelected} onClick={this.__onClick}>
            {isSelected ? 'Ales' : 'Alege'}
          </Button>
        )}
      </div>
    )
  }

  private __onClick = () =>
    this.props.onClick && this.props.onClick(this.props.id)

  private get __scheduleTime() {
    const { dayOfWeek, extraDaysOfWeek, startHour, endHour } = this.props

    let day = DAYS[dayOfWeek]

    if (extraDaysOfWeek !== null && extraDaysOfWeek !== '') {
      const extraDays = extraDaysOfWeek
        .split(',')
        .map(d => parseInt(d.trim(), 10))
        .map(d => DAYS[d])

      day += `, ${extraDays.reduce(
        (prev, current) => `${prev === '' ? '' : `${prev}, `}${current}`,
        ''
      )}`
    }

    return `${day}, ${startHour}:00 - ${endHour}:00`
  }

  private get __className() {
    const { isSelected } = this.props
    let className = 'ScheduleEntryBox'

    if (isSelected) {
      className += ' selected'
    }

    return className
  }
}
