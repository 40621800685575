/**
 * 🔡 Options display
 *
 * * This component displays a list of options in boxes
 */

import './index.scss'

import { shiftColor } from 'helpers/util'
import * as React from 'react'

interface IBoxOptionsProps {
  options: { text: string; color: string }[];
}

const getOptionStyle = (baseColor: string) => ({
  background: `linear-gradient(to top right, ${shiftColor(baseColor)}, ${baseColor})`
});

const BoxOptions: React.FunctionComponent<IBoxOptionsProps> = props => {
  return (
    <div className="BoxOptions">
      {props.options.map((option, i) => (
        <div className="option" key={i} style={getOptionStyle(option.color)}>
          {option.text}
        </div>
      ))}
    </div>
  );
};

export default BoxOptions;
