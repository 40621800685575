/* -------------------------------------------------------------------------- */
/*                              ✨ Projects Router                             */
/* -------------------------------------------------------------------------- */

import * as React from 'react'
import ProjectsPage from 'pages/Projects'

export interface IProjectsPageRouterProps {}

export default class ProjectsPageRouter extends React.Component<
  IProjectsPageRouterProps
> {
  public render() {
    return <ProjectsPage />
  }
}
