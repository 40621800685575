/* -------------------------------------------------------------------------- */
/*                           📚 Courses Page Listing                          */
/* -------------------------------------------------------------------------- */

/* -------------------------- 🌍 Global components -------------------------- */
import * as React from 'react'
import { History } from 'history'
import {
  Course,
  Package,
  ScheduleEntry,
  APIPackage,
  APICourse
} from 'helpers/types'

/* -------------------------- 🤝 Common components -------------------------- */
import Header from 'components/Header'

/* ------------------- 📚 Courses Page Listing components ------------------- */
import HelpBox from 'components/Courses/HelpBox'
import WeekSchedule from 'components/Courses/WeekSchedule'
import CoursesListing from 'components/Courses/CoursesListing'
import PackageOptions from 'components/Courses/PackageOptions'
import ScheduleOptions from 'components/Courses/ScheduleOptions'

/* ------------------------------ 🗿 Constants ------------------------------ */
import { DUMMY_COURSES, COLORS } from 'helpers/variables'
import { IPersonDetailsState } from 'components/Courses/PersonDetails'
import { getPaymentRequest, getPackages } from 'services'

export interface ICoursesPageProps {
  history: History<any>
  location: string
  params: {
    classId?: string
    packageId?: string
  }
}
export interface ICoursesPageState {
  course: Course | null
  selectedPackage: Package | null
  apiPackages: APIPackage[]
}

export default class CoursesPage extends React.Component<
  ICoursesPageProps,
  ICoursesPageState
  > {
  state: ICoursesPageState = {
    course: null,
    selectedPackage: null,
    apiPackages: []
  }

  async componentDidMount() {
    const apiPackages = await getPackages()
    this.setState({ apiPackages }, this.scrollHashElemIntoView)
    this.__updateStateFromLocation()
  }

  componentDidUpdate(prevProps: ICoursesPageProps) {
    if (prevProps.location !== this.props.location) {
      this.__updateStateFromLocation()
    }
  }

  onPaymentRequest = async (
    data: IPersonDetailsState,
    option: ScheduleEntry
  ) => {
    if (this.state.selectedPackage === null || this.state.course === null) {
      return
    }

    const requestData = await getPaymentRequest({
      ...data,
      packageId: this.state.selectedPackage.id,
      currentClassId: this.state.course.id,
      scheduleId: option.id
    })

    // Generate form
    const form = document.createElement('form')
    form.method = 'post'
    form.action = requestData.redirectUrl

    const envKeyInput = document.createElement('input')
    envKeyInput.name = 'env_key'
    envKeyInput.value = requestData.envKey

    const dataInput = document.createElement('input')
    dataInput.name = 'data'
    dataInput.value = requestData.data

    form.appendChild(envKeyInput)
    form.appendChild(dataInput)

    const root = document.querySelector('#root')
    if (root !== null) {
      root.appendChild(form)
      form.submit()
    }
  }

  scrollHashElemIntoView = () => {
    if (window.location.hash !== '') {
      let id: string
      if (window.location.hash.includes('webdev')) {
        id = '#webdev'
      } else if (window.location.hash.includes('python')) {
        id = '#python'
      } else {
        id = '#ccna'
      }
      const elem = document.querySelector(id)
      console.log('scrolling into view', id, elem)

      if (elem === null) {
        return
      }

      setTimeout(() => elem.scrollIntoView({ behavior: 'smooth' }), 1000)
    }
  }

  get courses() {
    if (this.state.apiPackages.length === 0) {
      return []
    }

    const apiCourses = this.state.apiPackages.map(pack => pack.classes).flat()
    const getCourseId = (course: Course) => {
      const apiCourse = apiCourses.find(apic => apic.type === course.type)

      if (apiCourse) {
        return apiCourse.id
      }

      return 0
    }

    return DUMMY_COURSES.map(course => ({
      ...course,
      id: getCourseId(course)
    }))
  }

  public render() {
    return (
      <>
        <Header isCustomHeader type='courses' />
        {this.__content}
        <HelpBox />
      </>
    )
  }

  private __updateStateFromLocation() {
    const { location, params } = this.props

    if (params.packageId !== undefined && params.classId !== undefined) {
      const selectedPackage = this.state.apiPackages
        .filter(pack => pack.id === parseInt(params.packageId || '0', 10))
        .map(
          (pack): Package => ({
            id: pack.id,
            price: pack.price,
            courses: pack.classes.map(course =>
              this._convertClass(course, pack.price)
            )
          })
        )[0]

      const apiCourse = this.state.apiPackages
        .map(pack => pack.classes)
        .flat()
        .find(p => p.id === parseInt(params.classId as string, 10))

      if (apiCourse === undefined) {
        console.log('noapicourse')
        return
      }

      let course = DUMMY_COURSES.find(c => c.type === apiCourse.type)

      if (course === undefined) {
        console.log('nocourse')
        return
      }

      course.id = apiCourse.id
      console.log(course)

      this.setState({
        selectedPackage,
        course
      })
    } else if (params.classId !== undefined) {
      const apiCourse = this.state.apiPackages
        .map(pack => pack.classes)
        .flat()
        .find(p => p.id === parseInt(params.classId as string, 10))

      if (apiCourse === undefined) {
        console.log('noapicourse')
        return
      }

      let course = DUMMY_COURSES.find(c => c.type === apiCourse.type)

      if (course === undefined) {
        console.log('nocourse')
        return
      }

      course.id = apiCourse.id
      console.log(course)

      if (course !== undefined) {
        this.setState({
          course
        })
      }
    } else if (location === '/cursuri') {
      this.setState({ course: null, selectedPackage: null })
    }
  }

  private get __content() {
    if (this.state.apiPackages.length === 0) {
      return <div className='loading'>Se încarcă...</div>
    }

    if (this.state.selectedPackage !== null) {
      return (
        <>
          <ScheduleOptions
            selectedPackage={this.state.selectedPackage}
            scheduleOptions={this.__selectedCourseScheduleOptions}
            onPaymentRequest={this.onPaymentRequest}
          />
        </>
      )
    } else if (this.state.course !== null) {
      return (
        <PackageOptions
          onPackageSelect={(selectedPackage: Package) => {
            this.props.history.push(
              `/cursuri/${(this.state.course as Course).id}/${
              selectedPackage.id
              }/orar`
            )
            this.setState({ selectedPackage })
          }}
          course={this.state.course}
          packages={this.__selectedCoursePackages}
        />
      )
    } else {
      return (
        <div className='courses'>
          <CoursesListing
            title='ccna'
            description='De la rețeaua de acasă până la infrastructurile Internetului vizibil și invizibil, află cum circulă pachetele și cum poți le urmărești și configurezi calea, optimizând întregul proces!'
            courses={this.courses.filter(
              course =>
                !course.type.includes('web') && !course.type.includes('python')
            )}
            color='#EAEAEA'
            onCourseClick={course => {
              this.props.history.push(`/cursuri/${course.id}`)
              this.setState({ course })
            }}
          />
          <CoursesListing
            title='webdev'
            description='Descoperă secretele browser-ului, află cum gesturile tale se propagă online și construiește platforme de socializare prin tehnologii web!'
            courses={this.courses.filter(course => course.type.includes('web'))}
            color={COLORS.yellow}
            onCourseClick={course => {
              this.props.history.push(`/cursuri/${course.id}`)
              this.setState({ course })
            }}
            isHalfWidth
            isLightText
          />
          <CoursesListing
            title='python'
            description='Adaugă-ți la arsenal uneltele Python, un limbaj de programare super-căutat pe care îl vei stăpâni de la cap la coaaaaaaaaaaaaaaadă!'
            courses={this.courses.filter(course =>
              course.type.includes('python')
            )}
            color={COLORS.blue}
            onCourseClick={course => {
              this.props.history.push(`/cursuri/${course.id}`)
              this.setState({ course })
            }}
            isHalfWidth
            isLightText
          />
          <WeekSchedule />
        </div>
      )
    }
  }

  private get __selectedCoursePackages(): Package[] {
    const { course } = this.state

    if (course === null) {
      return []
    }

    console.log(this.state.apiPackages, course)

    try {
      return this.state.apiPackages
        .filter(pack => pack.mainClass.id === course.id)
        .map(
          (apiPack): Package => ({
            id: apiPack.id,
            price: apiPack.price,
            courses: apiPack.classes.map(course =>
              this._convertClass(course, apiPack.price)
            )
          })
        )
        .sort((a, b) => a.courses.length > b.courses.length
          ? 1 : a.courses.length < b.courses.length ? -1 : 0)
    } catch (e) {
      console.error(e)
      return []
    }
  }

  private _convertClass = (apiCourse: APICourse, price: number): Course => {
    const courseData = DUMMY_COURSES.find(
      course => course.type === apiCourse.type
    )
    if (courseData) {
      return courseData
    }

    console.log('Did not find data for', apiCourse)

    return {
      price,
      id: apiCourse.id,
      description: '',
      title: apiCourse.type,
      type: apiCourse.type
    }
  }

  private get __selectedCourseScheduleOptions(): ScheduleEntry[] {
    const { apiPackages, course } = this.state

    if (course === null) {
      console.log('no courses')
      return []
    }

    const selected = apiPackages
      .map(pack => pack.classes)
      .flat()
      .find(c => course.id === c.id)

    if (selected === undefined) {
      console.log('no selected')
      return []
    }

    return selected.schedule
  }
}
