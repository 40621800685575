/* -------------------------------------------------------------------------- */
/*                           📚 Courses Page Router                           */
/* -------------------------------------------------------------------------- */

import * as React from 'react'
import CoursesPage from 'pages/Courses'
import { RouteComponentProps } from 'react-router'

export type ICoursesPageRouterProps = RouteComponentProps & {}

export default class CoursesPageRouter extends React.Component<
  ICoursesPageRouterProps
> {
  public render() {
    return (
      <CoursesPage
        history={this.props.history}
        location={this.props.location.pathname}
        params={this.props.match.params}
      />
    )
  }
}
