import { Project } from './types'

/* ---------------------------- 🏦 Sponsor logos ---------------------------- */
// import logo1 from './assets/sponsors/1.svg'
// import logo2 from './assets/sponsors/2.svg'
// import logo3 from './assets/sponsors/3.svg'
// import logo4 from './assets/sponsors/4.svg'
// import logo5 from './assets/sponsors/5.svg'
// import logo6 from './assets/sponsors/6.svg'

/* --------------------------- 🏃‍️ Project logos --------------------------- */
import awgWhite from './assets/projects/white/awg.svg'
import awgColor from './assets/projects/color/awg.svg'
import thWhite from './assets/projects/white/th.svg'
import thColor from './assets/projects/color/th.svg'
import acadnetWhite from './assets/projects/white/acadnet.svg'
import acadnetColor from './assets/projects/color/acadnet.svg'
import ioioiWhite from './assets/projects/white/ioioi.svg'
import ioioiColor from './assets/projects/color/ioioi.svg'

/* ------------------------- 🧰 TreasureHunt images ------------------------- */
import th1 from './assets/projects/gallery/th/TH1.jpg'
import th2 from './assets/projects/gallery/th/TH2.jpg'
import th3 from './assets/projects/gallery/th/TH3.jpg'
import th4 from './assets/projects/gallery/th/TH4.jpg'
import th5 from './assets/projects/gallery/th/TH5.jpg'
import th6 from './assets/projects/gallery/th/TH6.jpg'

/* ------------------------------ 🎮 AWG images ----------------------------- */
import awg1 from './assets/projects/gallery/awg/AWG1.jpg'
import awg2 from './assets/projects/gallery/awg/AWG2.jpg'
import awg3 from './assets/projects/gallery/awg/AWG3.jpg'
import awg4 from './assets/projects/gallery/awg/AWG4.jpg'
import awg5 from './assets/projects/gallery/awg/AWG5.jpg'
import awg6 from './assets/projects/gallery/awg/AWG6.jpg'
import awg7 from './assets/projects/gallery/awg/AWG7.jpg'
import awg8 from './assets/projects/gallery/awg/AWG8.jpg'

const SPONSORS: string[] = []
const PARTNERS: string[] = []
const GALLERY_IMAGES = [
  'https://picsum.photos/300/300?random',
  'https://picsum.photos/300/300?random',
  'https://picsum.photos/300/300?random',
  'https://picsum.photos/300/300?random',
  'https://picsum.photos/300/300?random',
  'https://picsum.photos/300/300?random',
  'https://picsum.photos/300/300?random',
  'https://picsum.photos/300/300?random'
]

export const PROJECTS: Project[] = [
  {
    name: 'AcadNet',
    type: 'Concurs pentru liceeni',
    description:
      'Dacă ești încă în liceu și dorești să îți etalezi cunoștințele din domeniul IT, te așteptăm la Concursul Național de Rețelistică, Calculatoare și Interoperabilitatea Software - AcadNet. Înscrie-te, pregătește-te din greu pentru concurs, califică-te la fază națională, ca mai apoi să ne întâlnim cu toții la Universitatea Politehnica București pentru ultima etapă a concursului, unde ne vom cunoaște mai bine. ',
    logo: {
      white: acadnetWhite,
      color: acadnetColor
    },
    sponsors: SPONSORS,
    partners: PARTNERS,
    location: 'Hol EC',
    date: '19 nov',
    url: 'http://acadnet.ro',
    gallery: GALLERY_IMAGES
  },
  {
    name: 'AWG',
    type: 'LAN Party',
    description:
      'Dacă îți plac jocurile în rețea, pe Xbox sau PlayStation, atunci LAN Party-ul ce anunță toamna este locul unde trebuie să fii! Fie că ești boboc sau veteran în Politehnica București, te așteptăm cu multe premii la o noapte întreagă de LoL, Hearthstone, FIFA, Just Dance și multe alte jocuri!',
    logo: {
      white: awgWhite,
      color: awgColor
    },
    sponsors: SPONSORS,
    partners: PARTNERS,
    location: 'Hol EC',
    date: '19 nov',
    url: 'http://awg.cs.pub.ro',
    gallery: [awg1, awg2, awg3, awg4, awg5, awg6, awg7, awg8]
  },
  {
    name: 'IOIOI',
    type: 'Boardgames Party',
    description:
      'Primăvara o sărbătorim cu IOIOI, evenimentul în care ne petrecem o seară întreagă în facultate jucând boardgames și cântând la karaoke. Bineînțeles, premiile nu vor lipsi pentru cei mai buni jucători și cântăreți!',
    logo: {
      white: ioioiWhite,
      color: ioioiColor
    },
    sponsors: SPONSORS,
    partners: PARTNERS,
    location: 'Hol EC',
    date: '19 nov',
    url: 'http://ioioi.cs.pub.ro',
    gallery: GALLERY_IMAGES
  },
  {
    name: 'TreasureHunt',
    type: 'Treasure Hunt',
    description:
      'Descoperă campusul nostru, rezolvă puzzle-urile și provocările, găsește indiciile, iar la final noi te răsplătim cu premii! Ia cu tine 3 colegi și vino să te distrezi căutând comori cu noi.',
    logo: {
      white: thWhite,
      color: thColor
    },
    sponsors: SPONSORS,
    partners: PARTNERS,
    location: 'Hol EC',
    date: '19 nov',
    url: 'http://th.ccna.ro',
    gallery: [th1, th2, th3, th4, th5, th6]
  }
]
