/**
 * 🚪 Main entry point
 *
 * * Sets up routes and extra dependencies (such as error logging, analytics, etc.)
 * ? TODO: Currently only renders homepage. Need to add dynamic router. See #11
 */

import * as React from 'react'
import {
  BrowserRouter as Router,
  Route,
  withRouter,
  RouteComponentProps
} from 'react-router-dom'

/* ------------------------ 📃 Page router components ----------------------- */
import HomePageRouter from './Home'
import CoursesPageRouter from './Courses'
import ProjectsPageRouter from './Projects'
import AboutUsPageRouter from './AboutUs'
import PaymentConfirmationPageRouter from './PaymentConfirmation'

export interface IMainProps {}

export default class Main extends React.Component<IMainProps, any> {
  public render() {
    return (
      <Router>
        <ScrollToTop>
          <Route exact path="/" component={HomePageRouter} />
          <Route path="/proiecte" component={ProjectsPageRouter} />
          <Route path="/desprenoi" component={AboutUsPageRouter} />
          <Route exact path="/cursuri" component={CoursesPageRouter} />

          {/* These two routes go to the same page */}
          <Route exact path="/cursuri/:classId" component={CoursesPageRouter} />
          <Route
            exact
            path="/cursuri/:classId/pachete"
            component={CoursesPageRouter}
          />

          <Route
            exact
            path="/cursuri/:classId/:packageId/orar"
            component={CoursesPageRouter}
          />
          <Route
            exact
            path="/cursuri/confirmarePlata"
            component={CoursesPageRouter}
          />
          <Route path="/return" component={PaymentConfirmationPageRouter} />
        </ScrollToTop>
      </Router>
    )
  }
}

/* ----------------------- ↕ Scroll restoration helper ---------------------- */
class ScrollToTopHelper extends React.Component<RouteComponentProps<{}>> {
  componentDidUpdate(prevProps: RouteComponentProps<{}>) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    return this.props.children
  }
}

const ScrollToTop = withRouter(ScrollToTopHelper)
