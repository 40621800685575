/**
 * 🍔 Page menu
 *
 * * Includes links to the pages of the website
 */

import './index.scss'

import * as React from 'react'
// 📷 Images
import facebookLogo from './assets/facebook.svg'
import instaLogo from './assets/insta.svg'
import messengerLogo from './assets/messenger.svg'
import { NavLink } from 'react-router-dom'

// 🧱 Constants
const SOCIAL_LINKS = [
  {
    title: 'fb',
    url: '/',
    image: facebookLogo
  },
  {
    title: 'insta',
    url: '/',
    image: instaLogo
  },
  {
    title: 'messenger',
    url: '/',
    image: messengerLogo
  }
]

export interface IMenuProps {}

export default class Menu extends React.Component<IMenuProps, any> {
  getMenuOption = (title: string, path: string) => {
    return (
      <NavLink exact activeClassName="selected" to={path}>
        <span>{title}</span>
      </NavLink>
    )
  }

  get socialLinks() {
    const links = SOCIAL_LINKS.map(link => (
      <a
        href={link.url}
        target="_blank"
        rel="noopener noreferrer"
        key={link.title}
      >
        <img src={link.image} alt={link.title} />
      </a>
    ))

    return <div className="social">{links}</div>
  }

  public render() {
    return (
      <nav>
        {this.getMenuOption('acasă', '/')}
        {this.getMenuOption('cursuri', '/cursuri')}
        {this.socialLinks}
        {this.getMenuOption('proiecte', '/proiecte')}
        {this.getMenuOption('despre noi', '/desprenoi')}
      </nav>
    )
  }
}
