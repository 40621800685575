/* -------------------------------------------------------------------------- */
/*                         📅 Weekly schedule display                         */
/* -------------------------------------------------------------------------- */

import './index.scss'
import * as React from 'react'
import { Course, ScheduleEntry, APIPackage } from 'helpers/types'
import { shiftColor } from 'helpers/util'
import { DEFAULT_COURSE_COLOR, DUMMY_COURSES } from 'helpers/variables'
import { getPackages } from 'services'

type ClassData = {
  type: Course['type'],
  days: ScheduleEntry['dayOfWeek'][]
  startHour: number,
  endHour: number,
  room: string
}

export interface IWeekScheduleProps {
}
export interface IWeekScheduleState {
  courses: ClassData[]
}

export default class WeekSchedule extends React.Component<IWeekScheduleProps, IWeekScheduleState> {
  state: IWeekScheduleState = {
    courses: [],
  }

  componentDidMount() {
    this.getCourses()
  }

  getCourses = async () => {
    let data = (await getPackages())
      .filter(
        p => p.classes.length === 1
      )
      .map((p: APIPackage) => ({
        type: p.mainClass.type,
        schedules: p.mainClass.schedule
      }))
      .map(d => d.schedules.map(
        s => ({
          type: d.type,
          ...s
        })
      ))
      .flat()
      .map(
        c => ({
          type: c.type,
          days: [
            c.dayOfWeek, ...(c.extraDaysOfWeek
              ? c.extraDaysOfWeek.split(',').map(d => parseInt(d.trim(), 10))
              : []
            )],
          startHour: c.startHour,
          endHour: c.endHour,
          room: c.room
        })
      )

    this.setState({ courses: data })
  }

  getDay = (day: number) => {
    return this.state.courses.filter(
      c => c.days.includes(day)
    )
  }

  public render() {
    return (
      <section className='WeekSchedule' >
        <header>
          <h1>orar</h1>
          <span>Vezi aici orarul tuturor claselor</span>
        </header>

        <div className="schedule">
          <Day name='Luni' classes={this.getDay(1)} />
          <Day name='Marți' classes={this.getDay(2)} />
          <Day name='Miercuri' classes={this.getDay(3)} />
          <Day name='Joi' classes={this.getDay(4)} />
          <Day name='Vineri' classes={this.getDay(5)} />
          <Day name='Sâmbătă' classes={this.getDay(6)} />
          <Day name='Duminică' classes={this.getDay(7)} />
        </div>
      </section>
    )
  }
}



export interface IDayProps {
  name: string
  classes: ClassData[]
}

export function Day(props: IDayProps) {
  return (
    <div className='Day'>
      <h1>{props.name}</h1>
      {
        props.classes.length > 0 ?
          props.classes.map(
            (c, key) => <DayEntry
              key={key}
              type={c.type}
              startHour={c.startHour}
              endHour={c.endHour}
              room={c.room}
            />
          )
          : <h2 className="noData">Nu sunt clase în această zi</h2>
      }
    </div>
  )
}

export interface IDayEntryProps {
  type: Course['type']
  startHour: number
  endHour: number
  room: string
}

export function DayEntry(props: IDayEntryProps) {
  let color: string = DEFAULT_COURSE_COLOR

  const course = DUMMY_COURSES.find(c => c.type === props.type)

  if (course && course.color) {
    color = course.color
  }

  const primaryColor = color
  const secondaryColor = shiftColor(primaryColor)

  const style = {
    background: `linear-gradient(to top right, ${primaryColor}, ${secondaryColor})`
  }

  return (
    <div className={`DayEntry ${props.type}`} style={style}>
      <h1>{props.type}</h1>
      <h2>Între orele {props.startHour}:00 - {props.endHour}:00</h2>
      <h3>Sala {props.room}</h3>
    </div>
  )
}
