// Image imports
import exploringImage from './assets/images/exploring.jpg'
import learningImage from './assets/images/learning.jpg'
import playingImage from './assets/images/playing.jpg'
import teachingImage from './assets/images/teaching.jpg'

export interface Keyword {id: number, word: string; image: string }

export const KEYWORDS: Keyword[] = [
  {
    id: 0,
    word: 'learning',
    image: learningImage
  },
  {
    id: 1,
    word: 'teaching',
    image: teachingImage
  },
  {
    id: 2,
    word: 'exploring',
    image: exploringImage
  },
  {
    id: 3,
    word: 'playing',
    image: playingImage
  }
];
