/* -------------------------------------------------------------------------- */
/*                          ✨ Projects Page Component                         */
/* -------------------------------------------------------------------------- */

import * as React from 'react'
import Header from 'components/Header'
import Projects from 'components/Home/components/Projects'
import ContactHomepageSection from 'components/Home/components/Contact'
import { PROJECTS } from 'components/Home/components/Projects/projects'

export interface IProjectsPageProps {}

export default class ProjectsPage extends React.Component<IProjectsPageProps> {
  public render() {
    return (
      <>
        <Header isCustomHeader type="projects" />
        <Projects projects={PROJECTS} />
        <ContactHomepageSection />
      </>
    )
  }
}
