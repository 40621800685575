/* -------------------------------------------------------------------------- */
/*                           👨 Person Details form                           */
/* -------------------------------------------------------------------------- */

import './index.scss'

import * as React from 'react'

/* -------------------------- 🤝 Common components -------------------------- */
import TextField from 'components/common/TextField'
import Button from 'components/common/Button'
import Axios from 'axios'

export interface IPersonDetailsProps {
  price: string | number
  showVoucherOption?: boolean
  onClick: (data: IPersonDetailsState) => void
}
export interface IPersonDetailsState {
  name: string
  email: string
  phone: string
  voucher: string
  isVoucherValid: boolean
}

export default class PersonDetails extends React.Component<
  IPersonDetailsProps,
  IPersonDetailsState
  > {
  state: IPersonDetailsState = {
    name: '',
    email: '',
    phone: '',
    voucher: '',
    isVoucherValid: true
  }

  onClick = () => {
    if (!this.isDisabled) {
      console.log('clc')
      this.props.onClick(this.state)
    }
  }

  setVoucher = async (voucher: string) => {
    if (voucher.length < 4) {
      this.setState({ isVoucherValid: false, voucher })
      return
    }

    try {
      const res = await Axios.get(`/api/checkVoucher/${voucher}`)
      if (res.data.hasOwnProperty('valid') && res.data.valid === false) {
        this.setState({ isVoucherValid: false, voucher })
      } else {
        this.setState({ isVoucherValid: true, voucher })
      }
    } catch (e) {
      this.setState({ isVoucherValid: false, voucher })
    }
  }

  get isDisabled() {
    const { name, email, phone, isVoucherValid } = this.state
    return name === '' || email === '' || phone === '' || !isVoucherValid
  }

  get price() {
    const { isVoucherValid, voucher } = this.state
    const { price } = this.props

    if (isVoucherValid && voucher !== '') {
      const discount = parseInt(price as string, 10) * 0.1
      return parseInt(price as string, 10) - discount
    } else {
      return price
    }
  }

  public render() {
    const { name, email, phone, voucher } = this.state

    return (
      <div className='PersonDetails'>
        <div className='textFields'>
          <TextField
            label='Nume'
            value={name}
            onChange={(name) => this.setState({ name })}
          />
          <TextField
            type='email'
            label='E-mail'
            value={email}
            onChange={(email) => this.setState({ email })}
          />
          <TextField
            type='tel'
            label='Telefon'
            value={phone}
            onChange={(phone) => this.setState({ phone })}
          />
          {this.props.showVoucherOption && (
            <TextField
              type='text'
              label={
                this.state.isVoucherValid || voucher === ''
                  ? 'Voucher'
                  : 'Voucher invalid ❌'
              }
              value={voucher}
              onChange={value => this.setVoucher(value)}
            />
          )}
        </div>
        <Button
          isLight
          disabled={this.isDisabled}
          subtitle={`Vei plăti ${this.price} RON`}
          onClick={this.onClick}
        >
          Înscrie-te
        </Button>
      </div>
    )
  }
}
