/* -------------------------------------------------------------------------- */
/*                         💌 Contact Homepage Section                        */
/* -------------------------------------------------------------------------- */

/* -------------------------------- 🎨 Styles ------------------------------- */
import './index.scss'

import * as React from 'react'
import { CONTACT_INFO } from './variables'

/* -------------------------- 🤝 Common components -------------------------- */
import TextField from 'components/common/TextField'
import Button from 'components/common/Button'
import FooterInfo from 'components/common/FooterInfo'
import { sendContactMessage } from 'services'

export interface IContactHomepageSectionProps { }

export default class ContactHomepageSection extends React.Component<
  IContactHomepageSectionProps
  > {
  get contactInfoItems() {
    return CONTACT_INFO.map((entry, key) => (
      <ContactInfoEntry key={key} {...entry} />
    ))
  }
  public render() {
    return (
      <section className='HomepageContact'>
        <div className='content'>
          <div className='top'>
            <header>
              <h1>contact</h1>
              <p>
                Hai să ținem legătura! Orice întrebare ai avea legată de
                academie sau de cursurile noastre, trimite-o prin formular și
                îți vom răspunde în cel mai scurt timp!
              </p>
              <div className='contactInfo'>{this.contactInfoItems}</div>
            </header>
          </div>
          <ContactForm />
          <iframe
            title='Contact Map'
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11394.74906480212!2d26.06469462677003!3d44.43957688311168!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b201ebafcdf7a3%3A0xeafb513bd76c5277!2sComputer%20Science%20College!5e0!3m2!1sen!2sro!4v1566925856998!5m2!1sen!2sro'
          ></iframe>
        </div>
        <FooterInfo />
      </section>
    )
  }
}

export interface IContactInfoEntryProps {
  photo: string
  text: string
}

const ContactInfoEntry: React.FunctionComponent<
  IContactInfoEntryProps
> = props => {
  return (
    <div className='entry'>
      <img alt='' src={props.photo} />
      <span>{props.text}</span>
    </div>
  )
}


export interface IContactFormProps {
}

export interface IContactFormState {
  isSent: boolean,
  isError: boolean,
  name: string,
  email: string,
  message: string
}

class ContactForm extends React.Component<IContactFormProps, IContactFormState> {
  state: IContactFormState = {
    isSent: false,
    isError: false,
    name: '',
    email: '',
    message: ''
  }

  onSend = async () => {
    const { name, email, message } = this.state

    try {
      await sendContactMessage({ name, email, message })
      this.setState({ isSent: true })
    } catch (e) {
      this.setState({ isError: true })
      setTimeout(() => this.setState({ isError: false }), 3000)
    }
  }

  public render() {
    const { isSent, isError } = this.state
    return (
      <div className={`ContactForm ${isSent && 'sent'} ${isError && 'error'}`}>
        <TextField
          onChange={(name) => this.setState({ name })}
          label='Nume'
          value={this.state.name}
        />
        <TextField
          onChange={(email) => this.setState({ email })}
          label='E-mail'
          value={this.state.email}
        />
        <TextField
          onChange={(message) => this.setState({ message })}
          label='Mesaj'
          value={this.state.message}
          isTextArea
        />
        <div className='buttonWrapper'>
          <Button isLight onClick={this.onSend}>
            Trimite
        </Button>
        </div>
      </div>
    );
  }
}
