/* -------------------------------------------------------------------------- */
/*                          ⚡ Network Request helpers                         */
/* -------------------------------------------------------------------------- */

import Axios from 'axios'
import { APIPackage, ContactMessage } from 'helpers/types'

/* ------------------------ 💰 Payment API functions ------------------------ */

export type PaymentRequestData = {
  envKey: string
  data: string
  redirectUrl: string
}

export async function getPaymentRequest(data: {
  name: string
  email: string
  phone: string
  packageId: number
  currentClassId: number
  scheduleId: number
}): Promise<PaymentRequestData> {
  try {
    const res = await Axios.post('/api/getPaymentRequest', data)
    const requestData = res.data as PaymentRequestData

    return requestData
  } catch (e) {
    throw new Error(e)
  }
}

export async function getPackages(): Promise<APIPackage[]> {
  try {
    const res = await Axios.get('/api/getPackages')
    return res.data as APIPackage[]
  } catch (e) {
    throw new Error(e)
  }
}

export async function sendContactMessage({ name, email, message }: Partial<ContactMessage>) {
  try {
    await Axios.post('/api/contactMessages', { name, email, message })
  } catch (e) {
    throw new Error(e)
  }
}